import React from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import './Form.css';
export { default as FormField } from './FormField/FormField';
export {
  default as FormFieldSeparator
} from './FormFieldSeparator/FormFieldSeparator';
export {
  default as FormSectionHeader
} from './FormSectionHeader/FormSectionHeader';
export { default as FormNote } from './FormNote/FormNote';
export { default as FormSection } from './FormSection/FormSection';
export { default as CheckboxGroup } from './CheckboxGroup/CheckboxGroup';
export { default as FormSelect } from './FormSelect/FormSelect';
const Form = ({
  handleSubmit,
  onCancel,
  cancelBtn = false,
  submitText = 'Enter the Lottery',
  formTitle,
  guidanceNotes,
  cancelText = 'Cancel',
  children,
  submitting = false,
  pristine,
  error,
  className,
  sectional
}) => {
  const handleCancelClick = e => {
    e.preventDefault();
    onCancel && onCancel();
  };
  const formClass = classNames('form-container', className);
  const formAreaClass = classNames('form__form-area', className);
  const formHeaderClass = classNames('form__header', className);
  return (
    <div className={formClass}>
      <form className="form" onSubmit={handleSubmit}>
        <div className={formHeaderClass}>
          <h1 className="form__header-title">{formTitle}</h1>
          {guidanceNotes && !error && (
            <p className="form__header-notes">{guidanceNotes}</p>
          )}
          {error && <p className="form__form-error">{error}</p>}
        </div>
        <div className={formAreaClass}>{children}</div>
        <div className="form__footer">
          <div className="form__submit">
            {cancelBtn && (
              <Button
                onClick={handleCancelClick}
                className="button button--secondary"
                disabled={pristine || submitting}
              >
                {cancelText}
              </Button>
            )}
            <Button disabled={pristine || submitting} type="submit">
              {submitText}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
