import React from 'react';
import './FormSection.css';

const FormSection = ({ title, children }) => (
  <div className="form__section">
    {title && <div className="form__section-title">{title}</div>}
    {children}
  </div>
);

export default FormSection;
