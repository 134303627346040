import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { campaignActions, campaignSelectors } from '../../modules/campaign';
import { settingsSelectors } from '../../modules/settings';
import EntryEmailValidate from '../../components/EntryEmailValidate/EntryEmailValidate';

const Confirm = ({
  campaignId,
  entryForm,
  token,
  confirmationLoading,
  confirmationSuccess,
  fetchEntryForm,
  confirmEntrant
}) => {
  useEffect(() => {
    fetchEntryForm({ campaignId });
    confirmEntrant({ token });
  }, [campaignId, token, fetchEntryForm, confirmEntrant]);

  return !confirmationLoading && entryForm ? (
    <EntryEmailValidate
      message={entryForm.confirmationMessage}
      success={confirmationSuccess}
      shareUrl={entryForm.shareUrl}
      shareMessage={entryForm.shareMessage}
      shareHashTags={entryForm.shareHashTags}
      twitterUsername={entryForm.twitterUsername}
    />
  ) : (
    <div>Loading</div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const campaignId = settingsSelectors.getCampaignId(state);
  const entryForm = campaignSelectors.getEntryForm(state);
  const confirmationLoading = campaignSelectors.getConfirmationLoading(state);
  const confirmationSuccess = campaignSelectors.getConfirmationSuccess(state);
  const params = queryString.parse(ownProps.location.search);
  const token = params.token;
  return {
    campaignId,
    entryForm,
    token,
    confirmationLoading,
    confirmationSuccess
  };
};

export default connect(
  mapStateToProps,
  {
    ...campaignActions
  }
)(Confirm);
