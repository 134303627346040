import React from "react";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import "./CheckboxGroup.css";
const CheckboxGroup = ({
  input,
  groupLabel,
  className,
  options,
  meta: { touched, error, warning },
}) => {
  const { name, onChange, onBlur, onFocus } = input;
  const inputValue = input.value;

  const rowClass = classNames(className, "form__row", "form__row--check");
  const inputClass = classNames("form__field-input--check", {
    "form__field-input--error": touched && error,
  });
  const labelClass = classNames("form__label", "form__label--check");
  const checkboxes = options.map(({ label, value }, index) => {
    const handleChange = (event) => {
      const arr = [...inputValue];
      if (event.target.checked) {
        arr.push(value);
      } else {
        arr.splice(arr.indexOf(value), 1);
      }
      onBlur(arr);
      return onChange(arr);
    };
    const checked = inputValue.includes(value);
    return (
      <div className={rowClass} key={index}>
        <input
          type="checkbox"
          name={`${name}[${index}]`}
          value={value}
          checked={checked}
          onChange={handleChange}
          onFocus={onFocus}
          id={`${name}[${index}]`}
          className={inputClass}
        />
        {label && (
          <label htmlFor={`${name}[${index}]`} className={labelClass}>
            <ReactMarkdown>{label}</ReactMarkdown>
          </label>
        )}
      </div>
    );
  });
  return (
    <div className="checkboxgroup">
      {groupLabel && <div className="checkboxgroup__label">{groupLabel}</div>}
      {touched &&
        ((error && <div className="form__error">{error}</div>) ||
          (warning && <div className="form__error">{warning}</div>))}
      <div>{checkboxes}</div>
    </div>
  );
};

export default CheckboxGroup;
