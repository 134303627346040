import React from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';
import { FormField, FormSelect } from '../Form/Form';
import { required } from '../../utils/validators';
import './AdditionalField.css';
const AdditionalField = ({ field, floatRight }) => {
  const fieldClass = classNames('additional-field', {
    'additional-field__short': field.shortField,
    'additional-field__right': floatRight
  });
  const validators = field.required ? [required] : [];
  const getField = field => {
    switch (field.type) {
      case 'select':
        return (
          <Field
            name={field.name}
            component={FormSelect}
            type={'select'}
            label={field.label}
            validate={validators}
            options={field.options}
          />
        );
      default:
        return (
          <Field
            name={field.name}
            component={FormField}
            type={field.type}
            label={field.label}
            placeholder={field.placeholder ? field.placeholder : field.label}
            validate={validators}
            className={fieldClass}
          />
        );
    }
  };
  return getField(field);
};
export default AdditionalField;
