import styled from "styled-components";

const Button = styled.button`
  padding: 0.5rem 1.2rem;
  border-radius: 0.2rem;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 700;
  text-decoration: none;
  white-space: nowrap;
  word-break: keep-all;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  box-shadow: 0 0 0 0.5px rgba(50, 50, 93, 0.01),
    0 2px 5px rgba(50, 50, 93, 0.1), 0 1px 2px rgba(50, 50, 93, 0.05);
  outline: 0;
  color: #fff;
  border: 1px solid ${(props) => props.theme.mainColor};
  background-color: ${(props) => props.theme.mainColor};
  :hover {
    background-color: #fff;
    color: ${(props) => props.theme.mainColor};
  }
`;

Button.defaultProps = {
  theme: {
    main: "#000",
  },
};
export default Button;
