import { createRoutine, promisifyRoutine } from "redux-saga-routines";
import { SubmissionError } from "redux-form";
import { call, put, takeEvery } from "redux-saga/effects";
import * as api from "../services/api";
const types = {
  FETCH_ENTRY_FORM: "line-up/event/FETCH_ENTRY_FORM",
  FETCH_PERFORMANCES: "line-up/event/FETCH_PERFORMANCES",
  CREATE_ENTRANT: "line-up/event/CREATE_ENTRANT",
  CONFIRM_ENTRANT: "line-up/event/CONFIRM_ENTRANT",
};
const createEntrant = createRoutine(types.CREATE_ENTRANT);
const campaignActions = {
  fetchEntryForm: createRoutine(types.FETCH_ENTRY_FORM),
  fetchPerformances: createRoutine(types.FETCH_PERFORMANCES),
  createEntrant,
  submitEntrantForm: promisifyRoutine(createEntrant),
  confirmEntrant: createRoutine(types.CONFIRM_ENTRANT),
};

function* fetchEntity(entity, apiFn, action) {
  yield put(entity.request({ ...action.payload }));
  const { response, error } = yield call(apiFn, { ...action.payload });
  if (response) yield put(entity.success({ response, ...action.payload }));
  else yield put(entity.failure({ error, ...action.payload }));
  yield put(entity.fulfill({ ...action.payload }));
}
export function* submitForm(entity, apiFn, action) {
  yield put(entity.request({ ...action.payload }));
  const { response, error } = yield call(apiFn, { ...action.payload });
  if (response) yield put(entity.success({ response, ...action.payload }));
  else yield put(entity.failure(new SubmissionError(error.errors)));
}
const campaignOperations = {
  watchFetchEntryForm: function* watchFetchEntryForm() {
    yield takeEvery(
      campaignActions.fetchEntryForm.TRIGGER,
      fetchEntity.bind(null, campaignActions.fetchEntryForm, api.fetchEntryForm)
    );
  },
  watchFetchPerformances: function* watchFetchEntryForm() {
    yield takeEvery(
      campaignActions.fetchPerformances.TRIGGER,
      fetchEntity.bind(
        null,
        campaignActions.fetchPerformances,
        api.fetchPerformances
      )
    );
  },
  watchCreateEntrant: function* watchCreateEntrant() {
    yield takeEvery(
      campaignActions.createEntrant.TRIGGER,
      submitForm.bind(null, campaignActions.createEntrant, api.createEntrant)
    );
  },
  watchConfirmEntrant: function* watchCreateEntrant() {
    yield takeEvery(
      campaignActions.confirmEntrant.TRIGGER,
      fetchEntity.bind(null, campaignActions.confirmEntrant, api.confirmEntrant)
    );
  },
};

const getEntity = (state, id) => (state ? state[id] : {});
const getPerformanceIds = (state) => state.campaign.performanceIds;
const getEntryFormId = (state) => state.campaign.entryFormId;
const campaignSelectors = {
  getEntryForm: (state) =>
    getEntity(state.entities.entryForm, getEntryFormId(state)),
  getPerformances: (state) =>
    getPerformanceIds(state).map((id) =>
      getEntity(state.entities.performance, id)
    ),
  getConfirmationLoading: (state) => state.campaign.confirmationLoading,
  getConfirmationSuccess: (state) => state.campaign.confirmationSuccess,
};

const store = (
  state = {
    entryFormId: null,
    performanceIds: [],
    confirmationSuccess: false,
    confirmationLoading: true,
  },
  action = {}
) => {
  switch (action.type) {
    case campaignActions.fetchEntryForm.TRIGGER:
    case campaignActions.fetchEntryForm.FAILURE: {
      return {
        ...state,
        entryFormId: null,
      };
    }
    case campaignActions.fetchEntryForm.SUCCESS: {
      return {
        ...state,
        entryFormId: action.payload.response.result,
      };
    }
    case campaignActions.fetchPerformances.TRIGGER:
    case campaignActions.fetchPerformances.FAILURE: {
      return {
        ...state,
        performanceIds: [],
      };
    }
    case campaignActions.fetchPerformances.SUCCESS: {
      return {
        ...state,
        performanceIds: action.payload.response.result,
      };
    }
    case campaignActions.confirmEntrant.TRIGGER:
      return {
        ...state,
        confirmationSuccess: false,
        confirmationLoading: true,
      };
    case campaignActions.confirmEntrant.SUCCESS:
      return {
        ...state,
        confirmationSuccess: true,
        confirmationLoading: false,
      };
    case campaignActions.confirmEntrant.FAILURE:
      return {
        ...state,
        confirmationSuccess: false,
        confirmationLoading: false,
      };
    default:
      return state;
  }
};
export default store;
export { campaignActions, campaignOperations, campaignSelectors };
