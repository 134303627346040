import React from 'react';
import ReactMarkdown from 'react-markdown';
import './EntryEmailValidate.css';

const EntryEmailValidate = ({ message, success }) => (
  <div>
    <div className='entry-success-container'>
      {success ? (
        <>{message && <ReactMarkdown>{message}</ReactMarkdown>}</>
      ) : (
        <span>This confirmation link is no longer valid</span>
      )}
    </div>
  </div>
);

export default EntryEmailValidate;
