import React from "react";
import classNames from "classnames";
import "./FormSelect.css";

import styled from "styled-components";

const StyledSelect = styled.select`
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.fontColor};
  &:focus {
    background-color: ${(props) => props.theme.backgroundColor};
  }
`;

const FormSelect = ({
  input,
  label,
  className,
  id,
  options,
  meta: { touched, error, value, warning },
}) => {
  const rowClass = classNames(className, "form__row");
  const inputClass = classNames("form__field-input", {
    "form__field-input--error": (touched && error) || (touched && warning),
  });
  const labelClass = classNames("form__label");
  return (
    <div className={rowClass}>
      {label && (
        <label className={labelClass} htmlFor={id}>
          {label}
        </label>
      )}
      <StyledSelect {...input} className={inputClass}>
        <option value="" disabled selected>
          Please Select...
        </option>
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </StyledSelect>
      {touched &&
        ((error && <div className="form__error">{error}</div>) ||
          (warning && <div className="form__error">{warning}</div>))}
    </div>
  );
};

export default FormSelect;
