import React from "react";
import { Field, reduxForm, FormSection as Section } from "redux-form";
import { required, email } from "../../utils/validators";
import Form, {
  FormField,
  FormSection,
  FormNote,
  CheckboxGroup,
} from "../Form/Form";
import AdditionalField from "./AdditionalField";
import "./EntryForm.css";

let EntryForm = ({
  submitting,
  handleSubmit,
  error,
  title,
  description,
  submitText,
  notes,
  performances = [],
  optIns = [],
  additionalFields = [],
}) => {
  return (
    <div>
      <Form
        sectional
        guidanceNotes={description}
        formTitle={title}
        submitText={submitText}
        handleSubmit={handleSubmit}
        submitting={submitting}
        error={error}
      >
        <div className="entry-form-container">
          <FormSection title="Your Details">
            <div className="entry-form-inline-row">
              <Field
                name="firstName"
                label="First Name"
                component={FormField}
                type="text"
                placeholder="Your first name"
                validate={[required]}
              />
              <Field
                name="lastName"
                label="Last Name"
                component={FormField}
                type="text"
                placeholder="Your last name"
                validate={[required]}
              />
            </div>
            <Field
              name="email"
              label="Email address"
              component={FormField}
              type="email"
              placeholder="Your email address"
              validate={[email, required]}
            />
          </FormSection>
          {performances && performances.length > 0 && (
            <FormSection>
              <Field
                name="performances"
                groupLabel="Select your performances"
                component={CheckboxGroup}
                options={performances.map((performance) => ({
                  label: performance.dateTimeString,
                  value: performance.id,
                }))}
              />
            </FormSection>
          )}

          {additionalFields && additionalFields.length > 0 && (
            <FormSection title="Double your chances to win by answering these questions">
              <Section name="additionalFields">
                {additionalFields.map((field, index) => {
                  return <AdditionalField key={index} field={field} />;
                })}
              </Section>
            </FormSection>
          )}
          <Field
            name="optIns"
            component={CheckboxGroup}
            options={optIns.map((optIn) => ({
              label: optIn.text,
              value: optIn.id,
            }))}
          />
          <FormNote>{notes}</FormNote>
        </div>
      </Form>
    </div>
  );
};

EntryForm = reduxForm({
  form: "entry",
})(EntryForm);

export default EntryForm;
