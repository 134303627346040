import React from "react";
import ReactMarkdown from "react-markdown";
import "./EntryFormSuccess.css";

const EntryFormSuccess = ({ message }) => (
  <div>
    <div className="entry-success-container">
      {message && <ReactMarkdown>{message}</ReactMarkdown>}
    </div>
  </div>
);

export default EntryFormSuccess;
