import React from "react";
import ReactMarkdown from "react-markdown";
import "./FormField.css";
import classNames from "classnames";
import { useTheme } from "styled-components";

const FormField = ({
  input,
  label,
  type,
  placeholder,
  className,
  id,
  inline,
  switchfield,
  labelAfter,
  width,
  rows,
  step,
  hintBefore,
  hintAfter,
  meta: { touched, error, warning },
}) => {
  const theme = useTheme();

  const style = () => {
    return {
      ...(width && { width: `${width}%` }),
      color: theme.fontColor,
      backgroundColor: theme.backgroundColor,
    };
  };

  const rowClass = classNames(className, "form__row", {
    "form__row--check": !inline && (type === "checkbox" || type === "radio"),
    "form__row--check-inline":
      inline && (type === "checkbox" || type === "radio"),
    "form__row--inline": inline && (type !== "checkbox" || type !== "radio"),
  });
  const inputClass = classNames({
    "form__field-input": type !== "checkbox" && type !== "radio",
    "form__field-input--check":
      !inline && (type === "checkbox" || type === "radio"),
    "form__field-input--check-inline":
      inline && (type === "checkbox" || type === "radio"),
    "form__field-input--inline":
      inline && type !== "checkbox" && type !== "radio",
    "form__field-input--error": (touched && error) || (touched && warning),
  });
  const labelClass = classNames("form__label", {
    "form__label--inline": inline && type !== "checkbox" && type !== "radio",
    "form__label--check-inline":
      (inline && type === "checkbox") || type === "radio",
    "form__label--switch": switchfield,
  });

  const Element = type === "textarea" ? "textarea" : "input";

  return (
    <div className={rowClass}>
      {label && (
        <label className={labelClass} htmlFor={id}>
          <ReactMarkdown>{label}</ReactMarkdown>
        </label>
      )}
      {hintBefore && (
        <div className="form__hint form__hint--before">{hintBefore}</div>
      )}
      <Element
        {...input}
        placeholder={placeholder}
        id={id}
        type={type}
        className={inputClass}
        rows={rows}
        step={step}
        style={style()}
      />
      {labelAfter && (
        <label className={labelClass} htmlFor={id}>
          <ReactMarkdown>{labelAfter}</ReactMarkdown>
        </label>
      )}
      {touched &&
        ((error && <div className="form__error">{error}</div>) ||
          (warning && <div className="form__error">{warning}</div>))}
      {hintAfter && (
        <div className="form__hint form__hint--after">{hintAfter}</div>
      )}
    </div>
  );
};

export default FormField;
