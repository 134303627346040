import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { bindPromiseCreators } from "redux-saga-routines";
import { campaignActions, campaignSelectors } from "../../modules/campaign";
import { settingsSelectors } from "../../modules/settings";
import { SubmissionError } from "redux-form";
import EntryForm from "../../components/EntryForm/EntryForm";

const Entry = ({
  campaignId,
  entryForm,
  performances,
  fetchEntryForm,
  fetchPerformances,
  sumbmitEntrantForm,
  history,
}) => {
  useEffect(() => {
    fetchEntryForm({ campaignId });
    fetchPerformances({ campaignId });
  }, [campaignId, fetchEntryForm, fetchPerformances]);

  const handleFormSubmit = (data) => {
    entryForm.optIns
      .filter((optIn) => optIn.required)
      .forEach((a) => {
        if (!data.optIns || !data.optIns.includes(a.id)) {
          throw new SubmissionError({
            optIns: `Please confirm: "${a.text}"`,
            _error: `Required data missing`,
          });
        }
      });
    if (
      performances.length > 0 &&
      (!data.performances || data.performances.length === 0)
    ) {
      throw new SubmissionError({
        performances: `Please select a performance.`,
        _error: `Required data missing`,
      });
    }
    const values = {
      ...data,
      campaignId: campaignId,
      optIns: data.optIns && data.optIns.map((optIn) => ({ id: optIn })),
      performances:
        data.performances &&
        data.performances.map((performance) => ({ id: performance })),
    };
    return sumbmitEntrantForm({ values, campaignId });
  };
  const handleSubmitSuccess = () => {
    history.push(`/success`);
  };
  return entryForm ? (
    <EntryForm
      title={entryForm.name}
      description={entryForm.description}
      optIns={
        entryForm.optIns && entryForm.optIns.sort((a, b) => a.index - b.index)
      }
      notes={entryForm.notes}
      submitText={entryForm.entryButtonText}
      additionalFields={entryForm.additionalFields}
      performances={performances}
      onSubmit={handleFormSubmit}
      onSubmitSuccess={handleSubmitSuccess}
    />
  ) : (
    <div>Loading</div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const campaignId = settingsSelectors.getCampaignId(state);
  const entryForm = campaignSelectors.getEntryForm(state);
  const performances = campaignSelectors.getPerformances(state);
  return {
    campaignId,
    entryForm,
    performances,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindPromiseCreators(
      {
        sumbmitEntrantForm: campaignActions.submitEntrantForm,
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...campaignActions,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
