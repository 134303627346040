import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import "iframe-resizer/js/iframeResizer.contentWindow";
import queryString from "query-string";
import "./index.css";
import App from "./App";
import configureStore from "./store/configureStore";
import { rootSaga } from "./modules";
import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();
const params = queryString.parse(document.location.search);

const settings = {
  campaignId: parseInt(params.campaignId, 10),
  mainColor: params.mainColor ?? "#399ba2",
  fontColor: params.fontColor ?? "#666",
  backgroundColor: params.backgroundColor ?? "#fff",
};
const initialState = {
  settings,
};
const store = configureStore(history, initialState);

store.runSaga(rootSaga);

ReactDOM.render(<App store={store} />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept("./App", () => {
    ReactDOM.render(<App store={store} />, document.getElementById("root"));
  });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
