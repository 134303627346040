import React from "react";
import { Provider } from "react-redux";
import Route from "./containers/Root";

const App = ({ store, theme }) => (
  <Provider store={store}>
    <Route />
  </Provider>
);
export default App;
