import { schema, normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import fetch from 'isomorphic-fetch';
import buildUrl from 'build-url';
import { ApiError, NetworkError } from '../utils/errors';

const API_ROOT = window.apiRoute || process.env.REACT_APP_API_ROOT;
const API_KEY = window.lukey || process.env.REACT_APP_PUBLIC_API_KEY;
const token = btoa(`${API_KEY}:`);

export const parseApiError = meta =>
  (meta.errors
    ? meta.errors
    : [{ attribute: '_error', messages: meta.message }]
  ).reduce(
    (obj, err) => {
      if (typeof err.messages === 'object') {
        obj[err.attribute] = parseApiError(err.messages);
      } else {
        obj[err.attribute] = err.messages;
      }
      return obj;
    },
    { _error: 'There was an error submitting the form' }
  );

const defaultConfig = (method = 'GET', body, authToken) => {
  let config = {
    method: method,
    headers: {
      'Content-Type': method === 'GET' ? null : 'application/json',
      Authorization: authToken ? `Bearer ${authToken}` : `Basic ${token}`
    },
    body: body ? JSON.stringify(body) : null
  };
  return config;
};

const fullUrl = (endpoint, queryParams) => {
  return buildUrl(API_ROOT, {
    path: endpoint,
    queryParams: queryParams
  });
};

const callApi = (endpoint, config = defaultConfig(), schema, queryParams) => {
  const url =
    endpoint.indexOf(API_ROOT) === -1
      ? fullUrl(endpoint, queryParams)
      : endpoint;
  return fetch(url, config)
    .then(
      response => response.json().then(json => ({ json, response })),
      err => Promise.reject(new NetworkError())
    )
    .then(({ json, response }) => {
      if (!response.ok) {
        return Promise.reject(new ApiError(parseApiError(json._metadata)));
      }
      const camelizedJson = camelizeKeys(json.data);
      const pagination = {
        ...camelizeKeys(json._metadata)
      };

      if (schema) {
        return {
          ...normalize(camelizedJson, schema),
          pagination: pagination
        };
      }
      return camelizedJson;
    })
    .then(response => ({ response }), error => ({ error }));
};

const entryFormSchema = new schema.Entity('entryForm');
export const fetchEntryForm = data =>
  callApi(
    `/campaign/${data.campaignId}/entry-form/`,
    defaultConfig(),
    entryFormSchema
  );

const performanceSchema = new schema.Entity('performance');
const performancesSchema = [performanceSchema];
export const fetchPerformances = data =>
  callApi(
    `/campaign/${data.campaignId}/performances/`,
    defaultConfig(),
    performancesSchema
  );

const entrantSchema = new schema.Entity('entrant');
export const createEntrant = data =>
  callApi(
    `/campaign/${data.campaignId}/entrant/`,
    defaultConfig('POST', data.values),
    entrantSchema
  );

export const confirmEntrant = data =>
  callApi(`/entrant/${data.token}/confirm/`, defaultConfig(), entrantSchema);
