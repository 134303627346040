import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { all, spawn } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import campaign, { campaignOperations } from './campaign';
import settings from './settings';
import merge from 'lodash/merge';

const entities = (state = {}, action) => {
  if (
    action.payload &&
    action.payload.response &&
    action.payload.response.entities
  ) {
    return merge({}, state, action.payload.response.entities);
  }
  return state;
};
export const getEntity = (state, id) => (state ? state[id] : {});

const rootReducer = combineReducers({
  entities,
  form: formReducer,
  campaign,
  settings
});

export function* rootSaga() {
  yield all([
    spawn(routinePromiseWatcherSaga),
    spawn(campaignOperations.watchFetchEntryForm),
    spawn(campaignOperations.watchFetchPerformances),
    spawn(campaignOperations.watchCreateEntrant),
    spawn(campaignOperations.watchConfirmEntrant)
  ]);
}
export default rootReducer;
