import React from 'react';
import { connect } from 'react-redux';
import { campaignSelectors } from '../../modules/campaign';
import { settingsSelectors } from '../../modules/settings';
import EntryFormSuccess from '../../components/EntryFormSuccess/EntryFormSuccess';

const Success = ({ campaignId, entryForm }) => {
  return <EntryFormSuccess message={entryForm.successMessage} />;
};

const mapStateToProps = (state, ownProps) => {
  const campaignId = settingsSelectors.getCampaignId(state);
  const entryForm = campaignSelectors.getEntryForm(state);
  return {
    campaignId,
    entryForm
  };
};

export default connect(
  mapStateToProps,
  null
)(Success);
