import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { settingsSelectors } from "../modules/settings";
import Entry from "./entryForm/Entry";
import Success from "./entryForm/Success";
import Confirm from "./entryForm/Confirm";

import styled from "styled-components";

const AppContainer = styled.div`
  color: ${(props) => props.theme.fontColor};
  background-color: ${(props) => props.theme.backgroundColor};
  a {
    color: ${(props) => props.theme.fontColor};
    text-decoration: underline;
  }
`;

const Root = ({ theme }) => {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <Router>
          <Route exact path="/" component={Entry} />
          <Route exact path="/success" component={Success} />
          <Route exact path="/confirm" component={Confirm} />
        </Router>
      </AppContainer>
    </ThemeProvider>
  );
};

const mapStateToProps = (state, ownProps) => {
  const theme = settingsSelectors.getTheme(state);
  return {
    theme,
  };
};

export default connect(mapStateToProps, null)(Root);
