export const integer = value => value && parseInt(value, 10);
export const positiveInt = value => value && Math.abs(parseInt(value, 10));
export const maxInt = max => value =>
  value && Math.min(Math.abs(parseInt(value, 10)), max);
export const float = value => value && parseFloat(value, 10);
export const upper = value => value && value.toUpperCase();

export const required = value =>
  value || value === 0 ? undefined : 'This field is required';
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
export const phone = value =>
  value && !/^([+]|[0-9])([0-9]{8,15})$/i.test(value)
    ? 'This phone number does not appear to be vaild'
    : undefined;
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
